/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

//

const EssayPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="essay-page w-full relative">
        <div className="grid pt-32 pb-24">
          <h1 className="animation-appear-slow grid-end-8 xs:grid-end-12 grid-start-3 xs:grid-start-1 pt-2 pb-2 mb-6 f1">
            {frontmatter.title}
          </h1>

          <div
            className="animation-appear-slow animation-delay-2 project-page__content grid-end-8 xs:grid-end-12 grid-start-3 xs:grid-start-1"
            dangerouslySetInnerHTML={{ __html: frontmatter.content }}
          ></div>
        </div>
      </Layout>
    </>
  );
};

export default EssayPage;

export const query = graphql`
  query EssayPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        content
        seoDescription
        seoKeywords
      }
    }
  }
`;
